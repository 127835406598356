import { connect } from "react-redux"
import ContactForm from "./contact_form"

const mSTP = (state, ownProps) => ({

})

const mDTP = dispatch => ({

})

export default connect(mSTP, mDTP)(ContactForm)